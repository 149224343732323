<template>
  <b-overlay :show="loading" rounded="sm">
    <b-card :title="`Generar factura electronica de orden #${order ? order.code : ''}`">
      <b-card-text>
        <div class="row">
          <div class="col">
            <h3>Factura <b class="text-primary">#{{ last_consecutive_invoice }}</b></h3>
          </div>
          <div class="col text-right">
            <h4>
              {{ date }} <br>
              {{ time }}
            </h4>
          </div>
        </div>
        <form-invoice :order="order" :invoice="invoice"/>
      </b-card-text>
    </b-card>
  </b-overlay>
</template>

<script>
import { BOverlay, BCard, BCardText } from 'bootstrap-vue'
import OrderService from '@/services/order.service'
import FormInvoice from '@/components/FormInvoice'
import Swal from 'sweetalert2'

import {
  confirmAlert
} from '@/helpers'

export default {
  components: {
    BOverlay,
    FormInvoice,
    BCard,
    BCardText
  },
  data() {
    return {
      loading: false,
      order: null,

      invoice: {
        currency: { name: 'Colones', value: 1 },
        payment_method: '',
        payment_form: '',
        terms: '',
        note: ''
      },

      date: '',
      time: '',
      last_consecutive_invoice: '',

      week: ['LUNES', 'MARTES', 'MIERCOLES', 'JUEVES', 'VIERNES', 'SABADO', 'DOMINGO'],
      clock: null
    }
  },
  methods: {
    async getOrder() {
      try {
        this.loading = true
        const { data: res } = await OrderService.getOrderDetailForInvoice(this.$route.query.order)
        if (res.success) {
          this.order = res.data.order
          this.last_consecutive_invoice = res.data.last_consecutive_invoice
        } else {
          const confirm = await confirmAlert(
            res.message,
            'Presione continuar para volver a la lista de ordenes',
            false
          )
          if (confirm.isConfirmed) {
            this.$router.push('/orders')
          }
        }
        this.loading = false
      } catch (error) {
        this.loading = false
        this.swal('Ha ocurrido un error al intentar cargar la información')
      }
    },
    swal(title, content = '') {
      Swal.fire({
        title: title,
        text: content,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    startClock() {
      this.clock = setInterval(() => {
        let cd = new Date();
        this.time = this.zeroPadding(cd.getHours(), 2) + ':' + this.zeroPadding(cd.getMinutes(), 2) + ':' + this.zeroPadding(cd.getSeconds(), 2)
        this.date = this.week[cd.getDay()] + ' ' + this.zeroPadding(cd.getDate(), 2) + '-' + this.zeroPadding(cd.getMonth()+1, 2) + '-' + this.zeroPadding(cd.getFullYear(), 4)
      }, 1000)
    },
    zeroPadding(num, digit) {
      let zero = '';
      for(var i = 0; i < digit; i++) {
        zero += '0';
      }
      return (zero + num).slice(-digit);
    }
  },
  created() {
    this.getOrder()
    this.startClock()
  },
  beforeDestroy() {
    clearInterval(this.clock)
  }
}
</script>
