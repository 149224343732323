import api from '@/api'

class InvoiceService {

  static getInvoices(page, search) {
    const params = { page: page, perPage: search.perPage }

    if (search.term) params.search = search.term

    return api({
      method: 'get',
      url: 'invoices',
      params: params,
    })
  }

  static getInvoice(id) {
    return api({
      url: `invoice-detail/${id}`
    })
  }

  static storeInvoice(data) {
    return api({
      method: 'post',
      data,
      url: 'store-invoice'
    })
  }

  static generatePdf(invoice_id) {
    return api({
      url: 'generate-invoice-pdf',
      params: { invoice_id },
      responseType: 'blob'
    })
  }

  static convertOrdersToInvoice(data) {
    return api({
      method: 'post',
      data,
      url: 'convert-orders-to-invoice'
    })
  }

}

export default InvoiceService
